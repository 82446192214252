import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { Section } from "../../components/Containers"
import { Image, Text } from "../../components/Core"

function TeamMember(props) {
  return (
    <div
      className="column is-4"
      key={props.publicId}
      style={{ padding: "0 5px" }}>
      <Image cloudName="nuvolum" publicId={props.publicId} />
      <div className="has-text-centered team-member-item">
        <h4 className="" style={{ margin: 0, marginBottom: "0" }}>
          {props.staffName}
        </h4>
        <p className="">{props.staffTitle}</p>
      </div>
      {/* <h6 className="image-caption staff staff-name">{props.staffName}</h6>
      <h6 className="image-caption staff staff-title light">
        {props.staffTitle}
      </h6> */}
    </div>
  )
}

TeamMember.propTypes = {
  publicId: PropTypes.string,
  staffName: PropTypes.string,
  staffTitle: PropTypes.string
}

function TeamRow(props) {
  const sideColumns = classNames({
    "is-2": props.members.length >= 4
  })
  return (
    <div className={`columns staff-row ${props.last ? "last" : ""}`}>
      <div className={`column ${sideColumns}`}></div>
      {props.members.map(member => {
        return (
          <TeamMember
            key={member.staffMember.imageId}
            publicId={member.staffMember.imageId}
            staffName={member.staffMember.staffName}
            staffTitle={member.staffMember.staffTitle}
          />
        )
      })}
      <div className={`column ${sideColumns}`}></div>
    </div>
  )
}

TeamRow.propTypes = {
  members: PropTypes.array
}

function MeetOurTeam(props) {
  if (!props.post.meetOurTeam.hasThisSection) return <></>

  var members = props.post.meetOurTeam.staffImages

  var memberRows = []
  var size = 5

  for (var i = 0; i < members.length; i += size) {
    memberRows.push(members.slice(i, i + size))
  }

  return (
    <Section colorBack={props.colorBack} className="meet-our-team">
      <div className="columns meet-our-team-heading">
        <div className="column is-4"></div>
        <div className="column mobile-col has-text-centered-tablet">
          <Text text={props.post.meetOurTeam.text} />{" "}
        </div>
        <div className="column is-4"></div>
      </div>

      {props.post.meetOurTeam.showStaff && (
        <>
          {memberRows.map((row, i) => {
            return (
              <React.Fragment key={i}>
                <TeamRow
                  listKey={i}
                  members={row}
                  last={i == memberRows.length - 1 ? true : false}
                />
              </React.Fragment>
            )
          })}
        </>
      )}
    </Section>
  )
}

MeetOurTeam.propTypes = {
  invert: PropTypes.bool,
  post: PropTypes.shape({
    meetOurTeam: PropTypes.shape({
      text: PropTypes.string,
      staffImages: PropTypes.array
    }).isRequired
  }).isRequired
}

export default MeetOurTeam
