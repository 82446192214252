import React from "react"

import { Image, Text } from "../../components/Core"
import { Section, Columns } from "../../components/Containers"
import TheaterVideo from "@components/Theater/TheaterVideo"

function TopSection(props) {
  return (
    <Section>
      {props.post.mainVideo.youtube && (
        <div className="columns mt-0 mb-3 has-text-centered about-main-theater-image">
          <div className="column is-full-width">
            <TheaterVideo
              buttonClass="contained"
              videoUrl={`https://www.youtube.com/watch?v=${props.post.mainVideo.youtube}`}
              controls
              playing
              overVideo
              language={props.language}>
              <Image
                wrapperClassName="show-desktop-only"
                publicId={props.post.mainVideo.imageId}
                useAR
              />
              <Image
                wrapperClassName="show-desktop-only-inverse"
                publicId={props.post.mainVideo.imageIdMobile}
                useAR
              />
            </TheaterVideo>
          </div>
        </div>
      )}

      <Columns sideColumnsSize={5}>
        <div className="column">
          <Text className="about__blurb" text={props.post.topBlurb} />
        </div>
      </Columns>
    </Section>
  )
}

export default TopSection
