import React from "react"
import { Text } from "../../components/Core"

function OurMission(props) {
  return (
    <>
      <section
        className="our-mission">
        <div
          style={{ width: "100%" }}
          className={`columns has-text-centered-tablet`}>
          <div className={`column is-${props.sideColumnIs}`}></div>
          <div className="column">
            <Text text={props.post.ourMission} />{" "}
          </div>
          <div className={`column is-${props.sideColumnIs}`}></div>
        </div>
      </section>
    </>
  )
}

export default OurMission
